import { createSelector } from "@reduxjs/toolkit";
import {
  deliveryAvailableSelector,
  isAddressDeliverySelector,
} from "../delivery/selectors";
import {
  isNowBetweenWorkingHoursSelector,
  storeSelector,
} from "../store/selectors";
import { totalCartProducts } from "../cart/selectors";

export const canCheckoutSelector = createSelector(
  isNowBetweenWorkingHoursSelector,
  deliveryAvailableSelector,
  storeSelector,
  totalCartProducts,
  isAddressDeliverySelector,
  (workingHours, deliveryAvailable, store, cartTotal, isAddressDelivery) =>
    workingHours &&
    deliveryAvailable &&
    (isAddressDelivery && store.minOrderValue !== null
      ? cartTotal >= store.minOrderValue
      : true)
);
