import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { Button } from "../styles";
import { client } from "../utils/api";

const PageButton = styled(Button)`
  color: ${({ active }) => (active ? "#fff !important" : "parent")};
`;

function PageItem({ to, children }) {
  const match =
    useRouteMatch({
      path: to,
      exact: true,
    }) !== null;

  return (
    <Link to={to}>
      <PageButton big active={match}>
        {children}
      </PageButton>
    </Link>
  );
}

function PageList() {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    async function data() {
      const response = await client("store/pages/");
      setPages(response);
    }

    data();
  }, []);

  return (
    <div className="d-flex" style={{ columnGap: "5px" }}>
      {pages.map((page) => (
        <PageItem key={page.pk} to={`/page/${page.slug}`}>
          {page.title}
        </PageItem>
      ))}
    </div>
  );
}

export default PageList;
