import { createSelector } from "@reduxjs/toolkit";
import { pickupLocationsByIdSelector } from "../store/selectors";
import { DELIVERY_ADDRESS, DELIVERY_PICKUP } from "./reducer";

export const deliverySelector = (state) => state.delivery;

export const deliveryAvailableSelector = createSelector(
  deliverySelector,
  (delivery) =>
    (delivery.type === DELIVERY_ADDRESS && delivery.cost !== null) ||
    (delivery.type === DELIVERY_PICKUP && delivery.pickup_location_id)
);

export const pickupLocationSelector = createSelector(
  deliverySelector,
  pickupLocationsByIdSelector,
  (self, pickupLocations) => pickupLocations[self.pickup_location_id]
);

export const isAddressDeliverySelector = createSelector(
  deliverySelector,
  (delivery) => delivery.type === DELIVERY_ADDRESS
);
