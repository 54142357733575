import styled from "styled-components";
import { useEffect, useRef } from "react";

const Overlay = styled.div`
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const ModalElement = styled.div`
  width: 400px;
  margin: 10% auto;
  padding: 15px;
  background: #fff;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 85%;
  }
`;

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

function Modal({ children, open, onClose, ...rest }) {
  const ref = useRef();

  useOnClickOutside(ref, () => open && onClose && onClose());

  return (
    <Overlay open={open}>
      <ModalElement open={open} {...rest} ref={ref}>
        {children}
      </ModalElement>
    </Overlay>
  );
}

export default Modal;
