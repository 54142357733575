import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { toPythonWeekday } from "../../utils/format";

export const storeSelector = (state) => state.store;

export const pickupLocationsSelector = createSelector(
  storeSelector,
  (store) => store.pickupLocations
);

export const pickupLocationsByIdSelector = createSelector(
  pickupLocationsSelector,
  (pickupLocations) => _.keyBy(pickupLocations, "id")
);

export const openingHoursSelector = createSelector(storeSelector, (self) =>
  [...self.openingHours].sort((a, b) => a.weekday - b.weekday)
);

export const isNowBetweenWorkingHoursSelector = createSelector(
  storeSelector,
  openingHoursSelector,
  (store, openingHours) => {
    const todayOpeningHours = _.find(openingHours, {
      weekday: toPythonWeekday(new Date()),
    });

    if (!todayOpeningHours) {
      return false;
    }

    const now = new Date();
    const dt1 = new Date();
    const dt2 = new Date();
    dt1.setHours(...todayOpeningHours.start.split(":"));
    dt2.setHours(...todayOpeningHours.end.split(":"));

    return now >= dt1 && now <= dt2;
  }
);
