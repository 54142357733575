import { useDispatch, useSelector } from "react-redux";
import { clearAddressAndCost } from "../modules/delivery/actions";
import { deliveryAvailableSelector } from "../modules/delivery/selectors";
import { ClickableDiv } from "../styles";
import { formatPrice } from "../utils/format";
import SearchLocationForm from "./searchLocationForm";

function AddressDelivery() {
  const dispatch = useDispatch();
  const { address, cost } = useSelector((state) => state.delivery);
  const deliveryAvailable = useSelector(deliveryAvailableSelector);

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        Adres dostawy
        {address && (
          <div
            onClick={() => {
              dispatch(clearAddressAndCost());
            }}
          >
            <ClickableDiv>wyczyść</ClickableDiv>
          </div>
        )}
      </div>
      {address ? (
        <div>
          {address}
          {deliveryAvailable && (
            <div>Dowóz jest możliwy &mdash; {formatPrice(cost)} zł</div>
          )}
          {!deliveryAvailable && (
            <div className="text-danger">Poza obszarem dowozu</div>
          )}
        </div>
      ) : (
        <SearchLocationForm />
      )}
    </>
  );
}

export default AddressDelivery;
