import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../utils/api";
import { productSlice } from "./reducer";

export const fetchByDate = createAsyncThunk(
  "products/fetchByDate",
  async (date, thunkAPI) => {
    thunkAPI.dispatch(productSlice.actions.setLoading(true));
    const response = await client(`store/products/?date=${date}`);
    thunkAPI.dispatch(productSlice.actions.setLoading(false));

    return response;
  }
);
