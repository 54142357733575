export const client = async (endpoint, { body, ...config } = {}) => {
  const headers = {
    "x-store-domain": window.location.host,
    "Content-Type": "application/json",
    Accept: "application/json",
    ...config.headers,
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(
    `${process.env["REACT_APP_API_URL"]}/${endpoint}`,
    {
      method: config.method || "GET",
      headers,
      ...config,
    }
  );

  if (response.status === 204) {
    return;
  }

  let json = null;
  try {
    json = await response.json();
  } catch (e) {}

  if (response.ok) {
    return json;
  } else {
    return Promise.reject({ response, json });
  }
};
