import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

const selfSelector = (state) => state.products;

export const availableProductsById = createSelector(
  selfSelector,
  (products) => products.byId
);

export const currentDateSelector = createSelector(
  selfSelector,
  (products) => products.date
);

export const categoriesSelector = createSelector(
  availableProductsById,
  (products) =>
    _.sortBy(
      _.uniqBy(
        _.remove(
          _.map(Object.values(products), "category"),
          _.negate(_.isNull)
        ),
        "id"
      ),
      "position"
    )
);
