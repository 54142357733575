import styled from "styled-components";

export const Products = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ProductItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #dde1e4;
  padding: 10px 0 10px;
  margin: 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const ProductImage = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  border: 1px solid #dde1e4;
  border-radius: 10px;
  margin-right: 10px;
  width: 100px;
  height: 100px;
`;

export const CartProductItem = styled.li`
  border-bottom: 1px solid #dde1e4;
  padding: 10px 0 10px;
`;

export const ProductCategory = styled(ProductItem)`
  margin-top: 17px;
  padding: 15px 0 0 0;
  border-bottom: none;
  font-size: 1rem;
`;

export const Scrollable = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

export const Badge = styled.div`
  padding: 4px 8px;
  display: inline-block;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: 500;
  border-radius: 5px;
`;

export const Circle = styled.div`
  position: relative;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
  font-weight: 500;
  width: ${({ size = 50 }) => size + "px"};
  height: ${({ size = 50 }) => size + "px"};
  border: 1px solid #5cb85c;
`;

export const Checkmark = styled.div`
  position: absolute;
  top: 45%;
  left: 29%;
  transform: rotate(45deg) translate(-50%, -50%);
  height: 25px;
  width: 15px;
  border-bottom: 1px solid #5cb85c;
  border-right: 1px solid #5cb85c;
`;
