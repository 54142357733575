import { createReducer } from "@reduxjs/toolkit";
import { fetchStore } from "./actions";

export default createReducer(
  {
    name: "",
    logo: "",
    description: "",
    phone: "",
    pickupLocations: [],
    deliveryRanges: [],
    openingHours: [],
    paymentTypes: [],
    deliveryTypes: [],
    minOrderValue: null,
    note: "",
  },
  {
    [fetchStore.fulfilled]: (state, { payload }) => {
      state.name = payload.name;
      state.logo = payload.logo;
      state.description = payload.description;
      state.phone = payload.phone;
      state.deliveryTypes = payload.delivery_types;
      state.pickupLocations = payload.pickup_locations;
      state.openingHours = payload.opening_hours;
      state.paymentTypes = payload.payment_types;
      state.minOrderValue = payload.min_order_value;
      state.note = payload.note;
    },
  }
);
