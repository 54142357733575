import { createReducer } from "@reduxjs/toolkit";
import { setCheckout } from "./actions";

export default createReducer(
  {
    name: "",
    phone: "",
    email: "",
    apartment_number: "",
    floor: "",
    business: "",
  },
  {
    [setCheckout]: (state, { payload }) => {
      state.name = payload.name;
      state.phone = payload.phone;
      state.email = payload.email;
      state.apartment_number = payload.apartment_number;
      state.floor = payload.floor;
      state.business = payload.business;
    },
  }
);
