import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import LoadingPlaceholder from "../components/loadingPlaceholder";
import { useFetch } from "../hooks";
import { Markup } from "react-render-markup";

function Page() {
  const { slug } = useParams();
  const { response: markup = { body: "" }, loading } = useFetch(
    `store/pages/${slug}/`
  );

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <Row className="g-0 bg-white rounded border justify-content-center">
      <Col className="p-3">
        <Markup markup={markup.body} />
      </Col>
    </Row>
  );
}

export default Page;
