import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { client } from "./utils/api";
import { storeSelector } from "./modules/store/selectors";

export const useFetch = (path) => {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    async function doFetch() {
      setLoading(true);

      const json = await client(path);

      !signal.aborted && setResponse(json);
      !signal.aborted && setLoading(false);
    }

    doFetch();

    return () => abortController.abort();
  }, [path]);

  return { response, loading };
};

export const useNote = () => {
  const { note } = useSelector(storeSelector);
  const [isSeen, setIsSeen] = useState(false);

  return { note: note ?? "", isSeen, setIsSeen };
};
