import { createAsyncThunk, createAction } from "@reduxjs/toolkit";

export const removeItem = createAction("cart/removeItem");
export const clearCart = createAction("cart/clear");

export const updateQty = createAsyncThunk(
  "cart/updateQty",
  async ({ id, val }, thunkAPI) => {
    const currentQty = thunkAPI.getState().cart[id] || 0;
    const nextQty = currentQty + val;

    if (nextQty < 1) {
      thunkAPI.dispatch(removeItem(id));
      return Promise.reject();
    } else {
      return nextQty;
    }
  }
);
