import { useDispatch, useSelector } from "react-redux";
import { productSlice } from "../modules/products/reducer";
import { currentDateSelector } from "../modules/products/selectors";
import { Button } from "../styles";
import { formatISODate } from "../utils/format";

const weekdayNames = {
  0: "niedziela",
  1: "poniedziałek",
  2: "wtorek",
  3: "środa",
  4: "czwartek",
  5: "piątek",
  6: "sobota",
};

const relativeNames = ["dziś", "jutro"];

function DatePicker() {
  const dispatch = useDispatch();
  const currentDate = useSelector(currentDateSelector);
  const now = new Date();
  const days = [...Array(7)].map((_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i);

    const diffDays = parseInt((date - now) / (1000 * 60 * 60 * 24), 10);
    const isoFormat = formatISODate(date);

    return {
      isoFormat,
      active: currentDate === isoFormat,
      humanReadableFormat:
        relativeNames[diffDays] ?? weekdayNames[date.getDay()],
    };
  });

  return (
    <ul className="list-inline my-1">
      <li className="list-inline-item">Menu na</li>
      {days.map(({ isoFormat, active, humanReadableFormat }, i) => (
        <li key={i} className="list-inline-item">
          <Button
            onClick={() => dispatch(productSlice.actions.setDate(isoFormat))}
            active={active}
          >
            {humanReadableFormat}
          </Button>
        </li>
      ))}
    </ul>
  );
}

export default DatePicker;
