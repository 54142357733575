import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import LoadingPlaceholder from "../components/loadingPlaceholder";
import PaymentStatus from "../components/paymentStatus";
import ProductsSummary from "../components/productSummary";
import { Badge, Checkmark, Circle, ProductItem } from "../components/styles";
import { client } from "../utils/api";
import { formatPrice, formatTime } from "../utils/format";
import { DELIVERY_ADDRESS } from "../modules/delivery/reducer";

function Order() {
  const { hashid } = useParams();
  const location = useLocation();
  const [order, setOrder] = useState({ products: [], date: "", status: "new" });
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const thankyou = location?.state?.thankyou;

  useEffect(() => {
    async function data() {
      try {
        setLoading(true);
        const response = await client(`orders/orders/${hashid}/`);
        setOrder(response);
      } catch ({ response }) {
        setNotFound(response.status === 404);
      } finally {
        setLoading(false);
      }
    }

    data();
  }, [hashid]);

  if (notFound) {
    return (
      <Row className="g-0 bg-white rounded border justify-content-center">
        <div className="my-5 text-center">
          <div style={{ fontSize: "55px" }} className="mb-2">
            😞
          </div>
          <h5>Nie odnaleziono</h5>
          <div className="mb-3">
            Zamówienie o takim identifykatorze nie zostało odnalezione
          </div>
          <Link to="/" replace>
            Powrót na stronę główną
          </Link>
        </div>
      </Row>
    );
  }

  if (loading) {
    return (
      <Row className="g-0 bg-white border rounded shadow justify-content-center">
        <LoadingPlaceholder>Ładowanie zamówienia</LoadingPlaceholder>
      </Row>
    );
  }

  return (
    <>
      {thankyou && (
        <div className="d-flex flex-wrap text-center text-lg-start justify-content-center align-items-center gap-3 mb-5 mt-lg-0 mt-5">
          <Circle>
            <Checkmark />
          </Circle>
          <div>
            <h4 style={{ fontWeight: "300" }} className="mb-0">
              Zamówienie zostało złożone
            </h4>
            <div>Potwierdzenie zostało wysłane na podany adres e-mail</div>
          </div>
        </div>
      )}
      <Row className="g-0 bg-white rounded border justify-content-center">
        <Col className="p-3" lg="6">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 style={{ fontWeight: "300" }}>Zamówienie nr {order.id}</h4>
            {order.is_canceled && <Badge color="#D63230">Anulowane</Badge>}
          </div>

          <div className="d-flex justify-content-between mb-1">
            <div>Na dzień</div>
            <div className="text-end">{order.date}</div>
          </div>
          {order.delivery_type === DELIVERY_ADDRESS && (
            <div className="d-flex justify-content-between mb-1">
              <div>Dostawa na adres</div>
              <div className="text-end">
                {order.delivery_address}
                {order.delivery_address_extra && (
                  <div>{order.delivery_address_extra}</div>
                )}
              </div>
            </div>
          )}
          {order.delivery_type === "pickup" && (
            <div className="d-flex justify-content-between mb-1">
              <div>Odbiór</div>
              <div className="text-end">
                {order.pickup_location.name} o {formatTime(order.pickup_at)}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between mb-1">
            <div>Płatność</div>
            <div className="text-end">
              <PaymentStatus order={order} />
            </div>
          </div>
          <hr />
          <ProductsSummary products={order.products} total={order.total}>
            {order.delivery_cost !== null && (
              <ProductItem>
                Dostawa <div>{formatPrice(order.delivery_cost)} zł</div>
              </ProductItem>
            )}
          </ProductsSummary>
        </Col>
      </Row>
    </>
  );
}

export default Order;
