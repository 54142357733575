import { useDispatch } from "react-redux";
import { updateQty } from "../modules/cart/actions";
import { Button } from "../styles";
import { formatPrice } from "../utils/format";
import { CartProductItem } from "./styles";

function CartItem({ product }) {
  const dispatch = useDispatch();

  const qty = (val) => {
    dispatch(updateQty({ id: product.id, val }));
  };

  return (
    <CartProductItem>
      <div className="mb-1">{product.name}</div>
      <div className="d-flex justify-content-between align-items-center">
        <div>{formatPrice(product.price)} zł</div>
        <div className="d-flex align-items-center ms-1">
          <Button onClick={() => qty(-1)}>-</Button>
          <div className="mx-2 text-center" style={{ width: "15px" }}>
            {product.qty}
          </div>
          <Button onClick={() => qty(1)}>+</Button>
        </div>
      </div>
    </CartProductItem>
  );
}

export default CartItem;
