import _ from "lodash";
import { useSelector } from "react-redux";
import { formatTime, toPythonWeekday } from "../utils/format";
import { openingHoursSelector } from "../modules/store/selectors";

function OpenSign({ children }) {
  const openingHours = useSelector(openingHoursSelector);
  const todayWeekday = _.filter(openingHours, {
    weekday: toPythonWeekday(new Date()),
  });

  const openHours = todayWeekday
    .map((w) => `${formatTime(w.start)} — ${formatTime(w.end)}`)
    .join(", ");

  if (todayWeekday.length === 0) {
    return children({ text: "Restraucja jest dzisiaj nieczynna" });
  } else if (todayWeekday) {
    return children({
      text: `Otwarte ${openHours}`,
    });
  }

  return null;
}

export default OpenSign;
