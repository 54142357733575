import { configureStore } from "@reduxjs/toolkit";
import _ from "lodash";
import cartReducer from "./modules/cart/reducer";
import storeReducer from "./modules/store/reducer";
import checkoutReducer from "./modules/checkout/reducer";
import deliveryReducer from "./modules/delivery/reducer";
import productsReducer from "./modules/products/reducer";

const loadPreloadedState = () => {
  const state = localStorage.getItem("state");
  if (state === null) {
    return undefined;
  }

  const parsed = JSON.parse(state);
  delete parsed.products;

  return parsed;
};

const store = configureStore({
  reducer: {
    products: productsReducer,
    cart: cartReducer,
    store: storeReducer,
    delivery: deliveryReducer,
    checkout: checkoutReducer,
  },
  preloadedState: loadPreloadedState(),
});

store.subscribe(
  _.throttle(() => {
    localStorage.setItem("state", JSON.stringify(store.getState()));
  }, 1000)
);

export default store;
