import { createReducer } from "@reduxjs/toolkit";
import {
  clearAddressAndCost,
  setAddressAndCost,
  setDeliveryCost,
  setDeliveryType,
  setPickupLocation,
} from "./actions";

export const DELIVERY_ADDRESS = "address";
export const DELIVERY_PICKUP = "pickup";

export default createReducer(
  {
    type: "",
    address: "",
    cost: null,
    pickup_location_id: null,
  },
  {
    [setAddressAndCost]: (state, { payload }) => {
      state.address = payload.address;
      state.cost = payload.cost;
    },
    [clearAddressAndCost]: (state) => {
      state.address = "";
      state.cost = null;
    },
    [setDeliveryType]: (state, { payload }) => {
      state.type = payload;
    },
    [setPickupLocation]: (state, { payload }) => {
      state.pickup_location_id = payload;
    },
    [setDeliveryCost]: (state, { payload }) => {
      state.cost = payload;
    },
  }
);
