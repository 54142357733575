import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Cart from "../components/cart";
import CategoryFilter from "../components/categoryFilter";
import DatePicker from "../components/datepicker.js";
import Delivery from "../components/delivery";
import ProductList from "../components/productList";
import { Scrollable } from "../components/styles";

function Frontpage() {
  const [categories, setCategories] = useState([]);

  return (
    <Row className="g-0 bg-white rounded border">
      <Col lg="8" className="border-end border-end-lg">
        <div className="border-bottom pt-3 px-3">
          <Scrollable>
            <CategoryFilter setFilteredCategories={setCategories} />
          </Scrollable>
        </div>
        <div className="p-3">
          <Scrollable style={{ height: "50px" }}>
            <DatePicker />
          </Scrollable>
          <ProductList categories={categories} />
        </div>
      </Col>
      <Col lg="4">
        <div className="p-3 border-bottom">
          <h5>Dostawa</h5>
          <Delivery />
        </div>
        <div className="p-3">
          <h5>Koszyk</h5>
          <Cart />
        </div>
      </Col>
    </Row>
  );
}

export default Frontpage;
