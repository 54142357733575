import { createReducer } from "@reduxjs/toolkit";
import { updateQty, removeItem, clearCart } from "./actions";

export default createReducer(
  {},
  {
    [updateQty.fulfilled]: (state, action) => {
      state[action.meta.arg.id] = action.payload;
    },
    [removeItem]: (state, action) => {
      delete state[action.payload];
    },
    [clearCart]: (state, action) => {
      return {};
    },
  }
);
