import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cartImg from "../images/basket.svg";
import {
  cartProductsSelector,
  totalCartProducts,
  totalCartSelector,
} from "../modules/cart/selectors";
import { setDeliveryCost } from "../modules/delivery/actions";
import { deliverySelector } from "../modules/delivery/selectors";
import { Button } from "../styles";
import { client } from "../utils/api";
import { formatPrice } from "../utils/format";
import CartItem from "./cartItem";
import { Products } from "./styles";
import Modal from "./modal";
import { canCheckoutSelector } from "../modules/checkout/selectors";
import { storeSelector } from "../modules/store/selectors";
import { DELIVERY_ADDRESS } from "../modules/delivery/reducer";

function Cart() {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector(cartProductsSelector);
  const total = useSelector(totalCartSelector);
  const { minOrderValue } = useSelector(storeSelector);
  const cartTotal = useSelector(totalCartProducts);
  const delivery = useSelector(deliverySelector);
  const canCheckout = useSelector(canCheckoutSelector);
  const [showDeliveryCostChange, setShowDeliveryCostChange] = useState(false);
  const [loading, setLoading] = useState(false);

  function navigate2Checkout() {
    history.push("/checkout");
  }

  async function checkout() {
    if (delivery.type === DELIVERY_ADDRESS) {
      setLoading(true);
      const response = await client(`store/distance?query=${delivery.address}`);
      if (response.cost !== delivery.cost) {
        dispatch(setDeliveryCost(response.cost));
        setShowDeliveryCostChange(true);
        setLoading(false);
      } else {
        navigate2Checkout();
      }
    } else {
      navigate2Checkout();
    }
  }

  if (cart.length === 0) {
    return (
      <div className="text-center my-3">
        <img src={cartImg} alt="Empty cart" style={{ width: "75px" }} />
        <h4 style={{ fontWeight: "300" }} className="mt-2 mb-0">
          Twój koszyk jest pusty
        </h4>
        <p>Spróbuj wybrać coś dla siebie</p>
      </div>
    );
  }

  return (
    <>
      <Products>
        {cart.map((product) => (
          <CartItem key={product.id} product={product} />
        ))}
        {delivery.type === DELIVERY_ADDRESS &&
          minOrderValue !== null &&
          cartTotal < minOrderValue && (
            <div className="text-center mt-2">
              Minimalna wartość zamówienia {formatPrice(minOrderValue)} zł
            </div>
          )}
        <div className="d-flex mt-2 justify-content-between align-items-center">
          <div>
            <div>Razem</div>
            <span style={{ fontSize: "1.25rem" }}>{formatPrice(total)}</span> zł
          </div>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <Button
              big
              onClick={checkout}
              disabled={!canCheckout}
              active={canCheckout}
            >
              Do kasy &rarr;
            </Button>
          )}
        </div>
      </Products>
      <Modal
        open={showDeliveryCostChange}
        onClose={() => setShowDeliveryCostChange(false)}
        className="text-center"
      >
        <div style={{ fontSize: "55px" }}>🚚</div>
        <h5>Koszty dostawy</h5>
        <div>
          {delivery.cost !== null
            ? `Koszt dostawy do ${delivery.address} zmienił się i aktualnie wynosi ${delivery.cost} zł.`
            : `Adres ${delivery.address} jest poza obszarem dowozu.`}
        </div>
        <Button secondary big onClick={navigate2Checkout} className="mt-4">
          Ok, zgoda
        </Button>
      </Modal>
    </>
  );
}

export default Cart;
