import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryType } from "../modules/delivery/actions";
import { DELIVERY_ADDRESS, DELIVERY_PICKUP } from "../modules/delivery/reducer";
import { Button } from "../styles";
import AddressDelivery from "./addressDelivery";
import Pickup from "./pickup";

function Delivery() {
  const deliveryType = useSelector((state) => state.delivery.type);
  const { deliveryTypes } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!deliveryType && deliveryTypes.length === 1) {
      dispatch(setDeliveryType(deliveryTypes[0]));
    }
  }, [deliveryType, deliveryTypes, dispatch]);

  if (!deliveryTypes) {
    return <div className="text-center">Bez możliwość odbioru lub dowozu</div>;
  }

  return (
    <>
      <div
        key="delivery-type"
        className="d-flex justify-content-between align-items-center"
      >
        <div>Sposób dostawy</div>
        <ul className="list-inline mb-0">
          {deliveryTypes.includes(DELIVERY_PICKUP) && (
            <li className="list-inline-item">
              <Button
                active={deliveryType === DELIVERY_PICKUP}
                onClick={() => dispatch(setDeliveryType(DELIVERY_PICKUP))}
              >
                Odbiór
              </Button>
            </li>
          )}
          {deliveryTypes.includes(DELIVERY_ADDRESS) && (
            <li className="list-inline-item">
              <Button
                active={deliveryType === DELIVERY_ADDRESS}
                onClick={() => dispatch(setDeliveryType(DELIVERY_ADDRESS))}
              >
                Dowóz
              </Button>
            </li>
          )}
        </ul>
      </div>
      {deliveryType && (
        <div className="mt-2">
          {deliveryType === DELIVERY_ADDRESS && <AddressDelivery />}
          {deliveryType === DELIVERY_PICKUP && <Pickup />}
        </div>
      )}
    </>
  );
}

export default Delivery;
