import _ from "lodash";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateQty } from "../modules/cart/actions";
import { productsInCart } from "../modules/cart/selectors";
import { fetchByDate } from "../modules/products/actions";
import {
  availableProductsById,
  currentDateSelector,
} from "../modules/products/selectors";
import { Button } from "../styles";
import { formatPrice } from "../utils/format";
import LoadingPlaceholder from "./loadingPlaceholder";
import { ProductCategory, ProductImage, ProductItem, Products } from "./styles";

function ProductList({ categories }) {
  const dispatch = useDispatch();
  const products = useSelector(availableProductsById);
  const date = useSelector(currentDateSelector);
  const loading = useSelector((state) => state.products.loading);
  const productsByCategory = _.groupBy(products, "category.id");
  const cartProducts = useSelector(productsInCart);

  useEffect(() => {
    dispatch(fetchByDate(date));
  }, [dispatch, date]);

  if (loading) {
    return <LoadingPlaceholder>Wczytywanie dań</LoadingPlaceholder>;
  }

  return (
    <Products>
      {categories.map((category) => (
        <Fragment key={category.id}>
          <ProductCategory>{category.name}</ProductCategory>
          {(productsByCategory[category.id] || []).map((product) => (
            <ProductItem key={product.id}>
              <div className="d-flex">
                {product.photo && <ProductImage url={product.photo} />}
                <div className="text-wrap">
                  {product.name}
                  <div className="text-muted mt-1">{product.description}</div>
                </div>
              </div>
              <Button
                big
                onClick={() => dispatch(updateQty({ id: product.id, val: 1 }))}
                style={{
                  backgroundColor: cartProducts.includes(product.id)
                    ? "#f2f9f3"
                    : "#fff",
                }}
              >
                {cartProducts.includes(product.id) ? "✓" : "+"}{" "}
                {formatPrice(product.price)} zł
              </Button>
            </ProductItem>
          ))}
        </Fragment>
      ))}
    </Products>
  );
}

export default ProductList;
