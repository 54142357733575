import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { DELIVERY_ADDRESS } from "../delivery/reducer";
import { availableProductsById } from "../products/selectors";

const selectSelf = (state) => state.cart;

export const cartProductsSelector = createSelector(
  selectSelf,
  availableProductsById,
  (cart, products) =>
    Object.entries(cart)
      .map(([productId, qty]) => ({
        ...products[productId],
        qty,
      }))
      .filter((p) => p.id)
);

export const productsInCart = createSelector(
  cartProductsSelector,
  (cartProducts) => cartProducts.map((p) => p.id)
);

export const totalCartProducts = createSelector(
  cartProductsSelector,
  (cartProducts) => _.sumBy(cartProducts, (p) => p.price * p.qty)
);

export const totalDelivery = createSelector(
  (state) => state.delivery,
  ({ type, cost }) => (type === DELIVERY_ADDRESS && cost ? cost : 0)
);

export const totalCartSelector = createSelector(
  totalCartProducts,
  totalDelivery,
  (...totals) => Math.max(0, _.sum(totals))
);

export const totalSelector = createSelector(
  totalCartProducts,
  totalDelivery,
  (...totals) => Math.max(0, _.sum(totals))
);
