import { Spinner } from "react-bootstrap";

function LoadingPlaceholder({ children }) {
  return (
    <div className="my-5 text-center">
      <Spinner animation="border" />
      <div className="mt-2">{children}</div>
    </div>
  );
}

export default LoadingPlaceholder;
