import { useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { pickupLocationsSelector } from "../modules/store/selectors";
import { setPickupLocation } from "../modules/delivery/actions";
import { pickupLocationSelector } from "../modules/delivery/selectors";

const Pickup = () => {
  const pickupLocations = useSelector(pickupLocationsSelector);
  const pickupLocation = useSelector(pickupLocationSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pickupLocation && pickupLocations.length === 1) {
      dispatch(setPickupLocation(pickupLocations[0].id));
    }
  }, [dispatch, pickupLocation, pickupLocations]);

  if (!pickupLocations.length) {
    return <div>Brak dostępnych punktów odbioru</div>;
  }

  if (pickupLocations.length > 1) {
    return (
      <FormControl
        as="select"
        onChange={(e) => dispatch(setPickupLocation(e.target.value))}
        value={(pickupLocation && pickupLocation.id) || ""}
        size="sm"
      >
        <option value="">Wybierz lokalizację</option>
        {pickupLocations.map((location) => (
          <option key={location.id} value={location.id}>
            {location.name}
          </option>
        ))}
      </FormControl>
    );
  }

  return <div>Odbiór w {pickupLocation && pickupLocation.name}</div>;
};

export default Pickup;
