import styled from "styled-components";
import headerBackground from "./images/headerBackground.png";

const primaryColor = "#047BFF";
const lightColor = "#d5dade";

export const Header = styled.div`
  height: 160px;
  width: 100%;
  background: #fff url(${headerBackground}) 15%;
  background-size: cover;
`;

export const Logo = styled.div`
  border: 1px solid #dee1e5;
  border-radius: 50%;
  background: #fff url(${(props) => props.bgImage}) center no-repeat;
  background-size: contain;
  width: 150px;
  height: 150px;

  @media (max-width: 576px) {
    width: 125px;
    height: 125px;
  }
`;

export const LogoLink = styled.a`
  position: relative;
  top: -25px;

  @media (max-width: 576px) {
    top: -10px;
  }
`;

export const Button = styled.button`
  padding: ${({ big }) => (big ? "7px 15px" : "2px 10px")};
  font-weight: 500;
  font-size: 14px;
  border: 1px solid ${({ active }) => (active ? primaryColor : lightColor)};
  border-radius: 4px;
  color: ${({ active }) => (active ? "#fff" : "#000")};
  background-color: ${({ active }) => (active ? primaryColor : "#fff")};
  white-space: nowrap;
  outline: none !important;
  transition: all 0.3s ease 0s;

  &:hover {
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
  }

  &:active:not([disabled]) {
    transform: translateY(4px);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const RadioButton = styled.label`
  width: 75px;
  height: 75px;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? "#D11E36" : "#ccc")};
  ${({ checked }) => checked && `background-color: #f2f9f3;`}
  border-radius: 5px;
  position: relative;
  margin: 5px 10px 23px 0;
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center center;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  & > input {
    display: none;
  }

  & > span {
    display: block;
    position: absolute;
    width: 100%;
    bottom: -23px;
    text-align: center;
    font-size: 0.85rem;
  }
`;

export const ClickableDiv = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;
