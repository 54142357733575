import { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAddressAndCost } from "../modules/delivery/actions";
import { client } from "../utils/api";

let autoComplete;

const loadScript = (url, callback) => {
  const exists = [].slice
    .call(
      document.getElementsByTagName("head")[0].getElementsByTagName("script")
    )
    .find((el) => el.src === url);

  if (exists) {
    return callback();
  }

  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: "pl" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
}

function SearchLocationForm() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    if (!query) {
      return;
    }

    setLoading(true);
    try {
      const response = await client(`store/distance?query=${query}`);

      setLoading(false);
      dispatch(setAddressAndCost(response));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup size="sm">
        <FormControl
          name="destination"
          placeholder="Wyszukaj swój adres dostawy"
          ref={autoCompleteRef}
          disabled={loading}
        />
        <Button type="submit" variant="outline-secondary" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Sprawdź"}
        </Button>
      </InputGroup>
    </Form>
  );
}

export default SearchLocationForm;
