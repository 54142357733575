import { useEffect, useReducer } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import OpenSign from "./components/openSign";
import PageList from "./components/pageList";
import ScrollToTop from "./components/scrollToTop";
import eatally from "./images/eatally_2.svg";
import { fetchStore } from "./modules/store/actions";
import Checkout from "./routes/checkout";
import Frontpage from "./routes/frontpage";
import Order from "./routes/order";
import Page from "./routes/page";
import { Button, Header, Logo, LogoLink } from "./styles";
import Modal from "./components/modal";
import { openingHoursSelector } from "./modules/store/selectors";
import { useNote } from "./hooks";

function App() {
  const store = useSelector((state) => state.store);
  const openingHours = useSelector(openingHoursSelector);
  const [showOpeningHours, toggleShowOpeningHours] = useReducer(
    (s) => !s,
    false
  );
  const { note, isSeen: noteIsSeen, setIsSeen: setNoteIsSeen } = useNote();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = store.name;
  }, [store]);

  useEffect(() => {
    dispatch(fetchStore());
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Container className="mb-4">
        <Row className="mb-2">
          <Col
            className="d-flex justify-content-start"
            style={{ columnGap: "5px" }}
          >
            <LogoLink as={Link} to="/">
              <Logo bgImage={store.logo} />
            </LogoLink>
            <div className="p-2">
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "21px",
                  marginBottom: "0",
                }}
              >
                {store.name}
              </h1>
              <div className="mt-1">{store.description}</div>
              <div className="mt-1">
                &#128222; <a href={`tel:${store.phone}`}>{store.phone}</a>
              </div>
              <Modal open={showOpeningHours} onClose={toggleShowOpeningHours}>
                <h5>Godziny otwarcia</h5>
                {openingHours.map((openingHour) => (
                  <div
                    key={openingHour.weekday}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      {
                        [
                          "Poniedziałek",
                          "Wtorek",
                          "Środa",
                          "Czwartek",
                          "Piątek",
                          "Sobota",
                          "Niedziela",
                        ][openingHour.weekday]
                      }
                    </div>
                    <div>
                      {openingHour.start} &mdash; {openingHour.end}
                    </div>
                  </div>
                ))}
              </Modal>
              <OpenSign>
                {({ text }) => (
                  <div
                    className="mt-1 cursor-pointer"
                    onClick={toggleShowOpeningHours}
                  >
                    {text} ⓘ
                  </div>
                )}
              </OpenSign>
            </div>
          </Col>
          <Col xs="12" lg="auto">
            <div className="pt-2">
              <PageList />
            </div>
          </Col>
        </Row>
        <Switch>
          <Route exact path="/">
            <Frontpage />
          </Route>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/order/:hashid">
            <Order />
          </Route>
          <Route path="/page/:slug">
            <Page />
          </Route>
          <Route path="*">
            <Redirect to="/" replace />
          </Route>
        </Switch>
        <div
          className="mt-2 mb-3 text-muted text-end"
          style={{ fontSize: "0.75rem" }}
        >
          <div>Aplikacja do zamawiania jedzenia</div>
          <div>eatally@ambrozkiewicz.com</div>
          <div>+48 790 251 519</div>
          <a href="https://eatally.pl" target="_blank" rel="noreferrer">
            <img src={eatally} width="75" alt="eatally logo" className="mt-2" />
          </a>
        </div>
      </Container>
      <Modal open={!noteIsSeen && note.length > 0}>
        <div className="text-center">
          <div style={{ whiteSpace: "pre-wrap" }}>{note}</div>
          <Button big className="mt-4" onClick={() => setNoteIsSeen(true)}>
            Zamknij
          </Button>
        </div>
      </Modal>
    </Router>
  );
}

export default App;
