import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { formatISODate } from "../../utils/format";
import { fetchByDate } from "./actions";

export const productSlice = createSlice({
  name: "products",
  initialState: {
    byId: {},
    date: formatISODate(new Date()),
    loading: false,
  },
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: {
    [fetchByDate.fulfilled]: (state, action) => {
      state.byId = _.keyBy(action.payload, "id");
    },
  },
});

export default productSlice.reducer;
