import { useEffect, useReducer, useState } from "react";
import { Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import ProductsSummary from "../components/productSummary";
import { ProductItem } from "../components/styles";
import cashIcon from "../images/cash-payment.svg";
import p24Icon from "../images/P24_logo.svg";
import cardIcon from "../images/payment.svg";
import { clearCart } from "../modules/cart/actions";
import { cartProductsSelector, totalSelector } from "../modules/cart/selectors";
import { setCheckout } from "../modules/checkout/actions";
import { DELIVERY_ADDRESS, DELIVERY_PICKUP } from "../modules/delivery/reducer";
import {
  deliveryAvailableSelector,
  pickupLocationSelector,
} from "../modules/delivery/selectors";
import { currentDateSelector } from "../modules/products/selectors";
import { Button, RadioButton } from "../styles";
import { client } from "../utils/api";
import Modal from "../components/modal";

function Checkout() {
  const cart = useSelector(cartProductsSelector);
  const total = useSelector(totalSelector);
  const {
    address,
    cost: deliveryCost,
    type: deliveryType,
  } = useSelector((state) => state.delivery);
  const pickupLocation = useSelector(pickupLocationSelector);
  const deliveryAvailable = useSelector(deliveryAvailableSelector);
  const checkoutData = useSelector((state) => state.checkout);
  const { paymentTypes } = useSelector((state) => state.store);
  const date = useSelector(currentDateSelector);
  const [apiError, dispatchAPIError] = useReducer(
    (state, action) => ({ ...state, ...action }),
    { message: "", show: false }
  );
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const selectedPaymentType = watch("payment_type");
  const dispatch = useDispatch();

  useEffect(() => {
    reset(checkoutData);
  }, [checkoutData, reset]);

  async function submit(data) {
    setLoading(true);

    let order = {
      ...data,
      delivery_type: deliveryType,
      date,
      products: cart.reduce((acc, product) => {
        acc.push({
          product: product.id,
          qty: product.qty,
        });
        return acc;
      }, []),
    };

    if (deliveryType === DELIVERY_ADDRESS) {
      order = {
        ...order,
        delivery_address: address,
        delivery_address_extra: Object.entries({
          "Nr mieszkania": data.apartment_number,
          Piętro: data.floor,
          Firma: data.business,
        })
          .filter(([, v]) => v.length > 0)
          .map(([field_name, field_value]) => `${field_name}: ${field_value}`)
          .join(", "),
      };
    } else if (deliveryType === DELIVERY_PICKUP) {
      order = { ...order, pickup_location: pickupLocation.id };
    }

    let response;
    try {
      response = await client("orders/orders/", {
        method: "POST",
        body: order,
      });
    } catch ({ json }) {
      const formFields = [
        "name",
        "phone",
        "comment",
        "payment_type",
        "pickup_at",
        "email",
      ];

      Object.entries(json)
        .filter(([field]) => formFields.includes(field))
        .forEach(([field, error]) => {
          setError(field, { type: "manual", message: error });
        });

      if (json.non_field_errors) {
        dispatchAPIError({ message: json.non_field_errors[0], show: true });
      }

      return false;
    } finally {
      setLoading(false);
    }

    dispatch(clearCart());
    dispatch(setCheckout(data));

    history.push(`/order/${response.id}`, { thankyou: true });

    if (response.payment_type === "przelewy24") {
      const paymentResponse = await client("orders/przelewy24", {
        method: "POST",
        body: { order: response.id },
      });

      window.location.href = paymentResponse.payment_url;
    }
  }

  if (Object.keys(cart).length === 0 || !deliveryAvailable) {
    return <Redirect to="/" />;
  }

  return (
    <Row className="g-0 bg-white rounded border">
      <Col className="border-end border-end-lg p-3">
        <h4 style={{ fontWeight: "300" }} className="mb-3">
          Nowe zamówienie
        </h4>
        {deliveryType === DELIVERY_ADDRESS && (
          <div className="mt-4 mb-3 d-flex justify-content-between">
            <>
              <div>Dostawa na adres</div>
              <div className="text-end">{address}</div>
            </>
          </div>
        )}
        <Form onSubmit={handleSubmit(submit)}>
          {deliveryType === DELIVERY_PICKUP && (
            <Form.Group className="mb-3">
              <Row className="align-items-center">
                <Col>
                  <div>Odbiór w</div>
                  <div style={{ fontSize: "21px" }}>{pickupLocation.name}</div>
                </Col>
                <Col lg={3} xs={6}>
                  <FloatingLabel label="Godzina odbioru">
                    <Form.Control
                      type="time"
                      {...register("pickup_at", { required: true })}
                      isInvalid={errors.pickup_at}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Form.Group>
          )}
          {deliveryType === DELIVERY_ADDRESS && (
            <Row className="mb-3">
              <Form.Group as={Col} xs={6} lg={4}>
                <FloatingLabel label="Nr Mieszkania">
                  <Form.Control
                    type="text"
                    placeholder="Np. 21D/50"
                    {...register("apartment_number")}
                    isInvalid={errors.apartment_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.apartment_number && errors.apartment_number.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} xs={6} lg={4}>
                <FloatingLabel label="Piętro">
                  <Form.Control
                    type="text"
                    {...register("floor")}
                    placeholder="Piętro"
                    isInvalid={errors.floor}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.floor && errors.floor.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={4} className="mt-3 mt-lg-0">
                <FloatingLabel label="Firma (opcjonalnie)">
                  <Form.Control
                    type="text"
                    placeholder="Firma (opcjonalnie)"
                    {...register("business")}
                    isInvalid={errors.business}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.business && errors.business.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
          )}
          <Row className="mb-3">
            <Form.Group as={Col}>
              <FloatingLabel label="Imię">
                <Form.Control
                  type="text"
                  placeholder="Twoje imię"
                  {...register("name", { required: "Musisz mieć jakieś imię" })}
                  isInvalid={errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name && errors.name.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label="Telefon">
                <Form.Control
                  type="tel"
                  placeholder="Numer kontaktowy"
                  {...register("phone", { required: "Pole wymagane" })}
                  isInvalid={errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone && errors.phone.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <FloatingLabel label="E-mail">
                <Form.Control
                  type="email"
                  placeholder="Wyślemy potwierdzenie zamówienia na ten adres"
                  {...register("email", { required: "Pole wymagane" })}
                  isInvalid={errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <FloatingLabel label="Komentarz">
              <Form.Control
                as="textarea"
                rows="3"
                {...register("comment")}
                placeholder="Dodatkowe wskazówki dla kierowcy lub kucharza; np. nazwa firmy, piętro, etc."
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="d-block">Sposób płatności</Form.Label>
            {paymentTypes.includes("cash") && (
              <RadioButton
                icon={cashIcon}
                isInvalid={errors.payment_type}
                checked={selectedPaymentType === "cash"}
              >
                <input
                  type="radio"
                  value="cash"
                  {...register("payment_type", { required: true })}
                />
                <span>Gotówka</span>
              </RadioButton>
            )}
            {paymentTypes.includes("card_on_the_spot") && (
              <RadioButton
                icon={cardIcon}
                isInvalid={errors.payment_type}
                checked={selectedPaymentType === "card_on_the_spot"}
              >
                <input
                  type="radio"
                  {...register("payment_type", { required: true })}
                  value="card_on_the_spot"
                />
                <span>Karta</span>
              </RadioButton>
            )}
            {paymentTypes.includes("przelewy24") && (
              <RadioButton
                icon={p24Icon}
                isInvalid={errors.payment_type}
                checked={selectedPaymentType === "przelewy24"}
              >
                <input
                  type="radio"
                  value="przelewy24"
                  {...register("payment_type", { required: true })}
                />
                <span>Przelewy24</span>
              </RadioButton>
            )}
          </Form.Group>
          <div className="text-end my-3">
            <div style={{ fontSize: "0.75rem" }} className="text-muted my-1">
              Klikając ZAMAWIAM zgadzasz się na zakup zawartości koszyka i z
              poprawnością wprowadzonych danych.
            </div>
            <Button big active type="submit" disabled={loading}>
              {loading && (
                <Spinner animation="border" variant="light" size="sm" />
              )}{" "}
              Zamawiam
            </Button>
          </div>
        </Form>
      </Col>
      <Col lg="4">
        <h4 style={{ fontWeight: "300" }} className="p-3 border-bottom">
          Koszyk
        </h4>
        <div className="p-3">
          <ProductsSummary products={cart} total={total}>
            {deliveryType === DELIVERY_ADDRESS && deliveryAvailable && (
              <ProductItem>
                Dostawa
                <div>{deliveryCost} zł</div>
              </ProductItem>
            )}
          </ProductsSummary>
        </div>
      </Col>
      <Modal open={apiError.show} className="text-center">
        <div style={{ fontSize: "55px" }}>💥</div>
        <h5>Błąd podczas składania zamówienia</h5>
        <div>{apiError.message}</div>
        <Button
          big
          onClick={() => dispatchAPIError({ show: false })}
          className="mt-4"
        >
          Zamknij
        </Button>
      </Modal>
    </Row>
  );
}

export default Checkout;
