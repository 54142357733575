import { formatPrice } from "../utils/format";
import { ProductItem, Products } from "./styles";

function ProductsSummary({ products, total, children }) {
  return (
    <>
      <Products>
        {products.map((product) => (
          <ProductItem key={product.id}>
            <div>
              {product.qty} x {product.name}
            </div>
            <div className="text-end text-nowrap">
              {formatPrice(product.price)} zł
            </div>
          </ProductItem>
        ))}
        {children}
      </Products>
      <div className="text-end">
        <div>Razem</div>
        <span style={{ fontSize: "1.25rem" }}>{formatPrice(total)}</span> zł
      </div>
    </>
  );
}

export default ProductsSummary;
