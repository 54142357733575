import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { categoriesSelector } from "../modules/products/selectors";
import { Button } from "../styles";

function CategoryFilter({ setFilteredCategories }) {
  const categories = useSelector(categoriesSelector);
  const [activeCategories, setActiveCategories] = useState([]);

  function addActiveCategory(categoryId) {
    if (activeCategories.includes(categoryId)) {
      setActiveCategories((state) => [
        ...state.filter((id) => id !== categoryId),
      ]);
    } else {
      setActiveCategories((state) => [...state, categoryId]);
    }
  }

  useEffect(() => {
    setFilteredCategories(
      activeCategories.length > 0
        ? categories.filter((c) => activeCategories.includes(c.id))
        : categories
    );
  }, [activeCategories, categories, setFilteredCategories]);

  return (
    <ul className="list-inline">
      <li className="list-inline-item">Kategorie</li>
      {categories.map((category) => (
        <li key={category.id} className="list-inline-item">
          <Button
            onClick={() => addActiveCategory(category.id)}
            active={activeCategories.includes(category.id)}
          >
            {category.name}
          </Button>
        </li>
      ))}
    </ul>
  );
}

export default CategoryFilter;
