import { Badge } from "react-bootstrap";
import { ClickableDiv } from "../styles";
import { client } from "../utils/api";

function PaymentStatus({ order }) {
  async function fetchPaymentUrl() {
    const paymentResponse = await client("orders/przelewy24", {
      method: "POST",
      body: { order: order.id },
    });

    window.location.href = paymentResponse.payment_url;
  }

  if (order.payment_type === "cash") {
    return "Gotówka";
  } else if (order.payment_type === "przelewy24") {
    return (
      <span>
        Przelewy24{" "}
        <Badge bg={order.is_paid ? "success" : "secondary"}>
          {order.is_paid ? "Zakończona" : "W toku"}
        </Badge>
        <br />
        {!order.is_paid && (
          <ClickableDiv className="d-inline" onClick={fetchPaymentUrl}>
            spróbuj ponownie
          </ClickableDiv>
        )}
      </span>
    );
  } else if (order.payment_type === "card_on_the_spot") {
    return "Karta";
  }

  return null;
}

export default PaymentStatus;
