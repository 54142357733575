export function formatPrice(price) {
  return Number(price).toFixed(2).replace(".", ",");
}

export function formatISODate(date) {
  return date.toISOString().slice(0, 10);
}

export function toPythonWeekday(date) {
  return (date.getDay() + 6) % 7;
}

export function formatTime(time) {
  return time.length > 0 ? time.split(":").slice(0, -1).join(":") : "";
}
